import { Heading, Icon, WriskTheme, xxlBumps } from '@wrisk/ui-components'

import logo from './assets/icons/logo.svg'
import heycar from './assets/images/heycar-motor-hero.png'
import fontFaces from './fonts'
import icons from './icons'
import illus from './illus'

export const heycarTheme: WriskTheme = {
  // Common
  name: 'Heycar',
  illus,
  fontFaces,
  icons,
  logo: {
    regular: logo,
  },
  breakpoints: ['480px', '768px', '1024px', '1200px', '1600px'],
  space: [
    '0rem', //  0    0px
    '0.25rem', //  1    4px
    '0.5rem', //  2    8px
    '0.75rem', //  3   12px
    '1.0rem', //  4   16px
    '1.25rem', //  5   20px
    '1.5rem', //  6   24px
    '1.75rem', //  7   28px
    '2.0rem', //  8   32px
    '2.5rem', //  9   40px
    '3.0rem', // 10   48px
    '3.5rem', // 11   56px
    '4.0rem', // 12   64px
    '4.5rem', // 13   72px
    '5.0rem', // 14   80px
  ],
  fonts: {
    body: `'Objektiv Mk3', sans-serif`,
    header: `'Objektiv Mk3', sans-serif`,
    navbar: `'Objektiv Mk3', sans-serif`,
    buttons: `'Objektiv Mk3', sans-serif`,
    input: `'Objektiv Mk3', sans-serif`,
    licencePlate: `'UKLicencePlate', sans-serif`,
  },
  lineHeights: ['1em', '1.1em', '1.2em', '1.3em', '1.4em', '1.5em'],
  typoSizes: {
    xxl: {
      fontSize: ['28px', '32px', '40px', '48px'],
      lineHeight: ['36px', '40px', '48px', '56px'],
    },
    xl: {
      fontSize: ['24px', '24px', '28px', '32px'],
      lineHeight: ['32px', '32px', '36px', '40px'],
    },
    lg: {
      fontSize: ['20px', '20px', '24px'],
      lineHeight: ['28px', '28px', '32px'],
    },
    md: {
      fontSize: ['18px', '18px', '20px'],
      lineHeight: ['24px', '24px', '28px'],
    },
    base: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    sm: {
      fontSize: '14px',
      lineHeight: '20px',
    },
    xs: {
      fontSize: '12px',
      lineHeight: '18px',
    },
  },
  radii: ['0px', '0px', '0px', '0px', '0px'],
  shadows: {
    default: '0 0 0 1px #e6e6e6',
    hover: '0 2px 4px 0 rgba(0, 0, 0, 0.06)',
    input: '0 0 0 2px #044bbc',
    inset: 'inset -8px 0 0 0 #fff, inset 8px 0 0 0 #fff',
    popupMenu: '0px 1px 6px 1px rgba(0, 0, 0, 0.08)',
    radioNested: 'inset 0 0 0 1px #E6E6E6',
    radioStandalone: 'inset 0 0 0 1px #E6E6E6',
    radioHover: 'inset 0 0 0 1px #303030',
    radioChecked: 'inset 0 0 0 2px #164CA3',
  },
  transitions: {
    default: 'all 250ms ease',
  },
  navigation: {
    height: ['64px'],
  },
  colors: {
    chalk: '#ffffff',
    background: '#ffffff',
    body: '#303030',
    bodySecondary: '#303030b3',
    hyperlink: '#044bbc',
    focus: '#044bbc',
    hover: '#f8faff',

    primaryAccent: '#044bbc',
    secondaryAccent: '#cdf1ec',

    foregroundPromoBanner: '#303030',
    backgroundPromoBanner: '#C0E6FF',

    foregroundPromoActivated: '#fff',
    backgroundPromoActivated: '#018f7c',

    textOnNavigation: '#ffffff',
    surfaceNavigation: '#044bbc',

    textInfo: '#044BBC',
    surfaceInfoSubdued: '#044bbc1a',
    surfaceInfo: '#044bbc',

    textCritical: '#f36451',
    textOnCritical: '#fff',
    surfaceCritical: '#f36451',
    surfaceCriticalHovered: '#f36451',
    surfaceCriticalSubdued: '#f364511a',

    textWarning: '#303030',
    textOnWarning: '#303030',
    surfaceWarning: '#FAC361',
    surfaceWarningSubdued: '#FAC3611a',

    textHighlight: '#303030',
    textOnHighlight: '#303030',
    surfaceHighlight: '#f2f2f2',
    surfaceHighlightSubdued: '#eaeaea',

    textSuccess: '#018f7c',
    textOnSuccess: '#fff',
    surfaceSuccess: '#018f7c',
    surfaceSuccessSubdued: '#018f7c1a',

    border: '#e6e6e6',
    divider: '#f2f2f2',
    inactive: '#30303059',
    placeholder: '#30303059',

    textDisabled: '#979797',
    surfaceDisabled: '#eaeaea',

    textFooter: '#ffffff',
    dividerFooter: '#ffffff33',
    surfaceFooter: '#082c73',

    radioCheckedBackground: '#F1F4FA',
    radioCheckedForeground: '#164CA3',

    dragBoxBorderActive: '#4F46E5',
    dragBoxBorderDefault: '#4B5563',

    progressBackgroundColor: '#9CA3AF',
    progressBarColor: '#4F46E5',
  },
  proposalImages: {
    'heycar-motor': heycar as string,
  },
  promoImages: {},
  // Components
  Heading: {
    default: {},
    h1: {
      fontFamily: 'Objektiv Mk3 Bold',
    },
    h2: {},
    h3: {},
    h4: {
      fontWeight: 'normal',
    },
  },
  Logo: {
    default: {
      display: 'block',
      maxHeight: ['2.5rem', '3rem'],
    },
  },
  NavAnchor: {
    default: {
      fontWeight: 'bold',
      height: '100%',
      borderRadius: '0',
      [Icon]: {
        path: {
          fill: 'textOnNavigation',
        },
      },
      '&:hover': {
        backgroundColor: '#082c73',
      },
    },
    inverted: {},
  },
  Banner: {
    default: {
      bg: '#ecf7ff',
      py: xxlBumps,
      mb: xxlBumps,
      [Heading]: {
        color: 'primaryAccent',
      },
    },
    alternative: {},
  },
  AccountIcon: {
    default: {
      bg: 'transparent',
      color: 'body',
      borderWidth: '1.6px',
      borderColor: 'chalk',
      borderStyle: 'solid',
    },
  },
  PrimaryButton: {
    variants: {
      default: {
        bg: 'primaryAccent',
        color: 'chalk',
        '&:hover': {
          bg: '#082C73',
        },
      },
      inverted: {
        bg: 'primaryAccent',
        color: 'chalk',
      },
      critical: {
        bg: 'surfaceCritical',
        color: 'textOnCritical',
      },
    },
    disabled: {
      default: {
        bg: 'surfaceDisabled',
        color: 'textDisabled',
        '&:hover': {
          bg: 'surfaceDisabled',
          color: 'textDisabled',
        },
      },
    },
  },
  SecondaryButton: {
    variants: {
      default: {
        bg: 'secondaryAccent',
        color: 'body',
        '&:hover': {
          bg: '#99e5db',
        },
      },
      inverted: {},
    },
    disabled: {
      default: {
        bg: 'surfaceDisabled',
        color: 'textDisabled',
        '&:hover': {
          bg: 'surfaceDisabled',
          color: 'textDisabled',
        },
      },
    },
  },
  TertiaryButton: {
    variants: {
      default: {
        boxShadow: 'inset 0 0 0 1px #044bbc',
        color: 'primaryAccent',
        '&:hover': {
          boxShadow: 'none',
          color: 'body',
          bg: '#e7ebf5',
        },
      },
      inverted: {
        boxShadow: 'inset 0 0 0 1px #fff',
        color: 'chalk',
      },
    },
    disabled: {
      default: {
        bg: 'surfaceDisabled',
        color: 'textDisabled',
        boxShadow: 'none',
        '&:hover': {
          bg: 'surfaceDisabled',
          color: 'textDisabled',
        },
      },
    },
  },
  StyledPremium: {
    default: {},
    inverted: {
      color: 'primaryAccent',
      fontFamily: 'Objektiv Mk3 Bold',
    },
  },
}
